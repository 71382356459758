import {
  EProductCardTagType,
  IProductCardInfoLine,
  type IProductCardTag,
  type ISimplifiedProduct,
} from '@insuma/mpp-ui/components/product-card';
import { ECustomerSource } from 'core/model/enums/customer.enum';
import { EPromotionDetailType, EPromotionType } from 'core/model/enums/promotion.enum';
import { IPromoCart } from 'core/model/interfaces/discount.interface';
import {
  ICartProduct,
  IProduct,
  IUnitMeasure,
  IUnitMeasureDetail,
  TGenericUnitMeasure,
} from 'core/model/interfaces/product.interface';
import { canPurchaseProduct } from './credit.utils';
import { cloneDeepSimple } from './object.utils';

interface ICardProductData {
  product: ISimplifiedProduct;
  unitMeasure: IUnitMeasure;
  tags: Array<IProductCardTag>;
  infoLine: IProductCardInfoLine | undefined;
}

interface ISearchCardProductData {
  product: ISimplifiedProduct;
  tags: Array<IProductCardTag>;
  infoLine: IProductCardInfoLine | undefined;
}

interface IProductToCardProductDataParams {
  product: IProduct;
  cartProduct?: ICartProduct;
  customerSource: ECustomerSource;
  canPurchaseDex: boolean;
  canPurchaseApudex: boolean;
}

export const getProductFinalPrice = (product: IProduct) => product.price;
export const getProductCommercialName = (commercialName: string | undefined | null, fallbackName: string) => {
  if (commercialName !== '' && commercialName !== null && commercialName !== undefined) return commercialName;
  return fallbackName;
};
export const shouldHighlightFinalPrice = (unitMeasure: IUnitMeasure) => {
  const hasSimpleDiscount = unitMeasure.promotions?.some(promo => promo.type === EPromotionType.SIMPLE_DISCOUNT);
  const hasTieredDiscount = unitMeasure.promotions?.some(promo => promo.type === EPromotionType.TIERED_DISCOUNT);
  return hasSimpleDiscount || hasTieredDiscount;
};
export const shouldHighlightFinalPriceInDetail = (unitMeasure: IUnitMeasureDetail) => {
  const hasSimpleDiscount = unitMeasure.promotions.simple.some(promo => promo.type === EPromotionDetailType.DISCOUNT);
  const hasTieredDiscount = unitMeasure.promotions.tiered.some(promo => promo.type === EPromotionDetailType.DISCOUNT);
  return hasSimpleDiscount || hasTieredDiscount;
};
export const productHasCombo = (product: IProduct) => product.selectedUnitMeasure.hasCombo;
export const hasReducedPrice = (promos: Array<IPromoCart>) => promos.filter(promo => promo.value !== null).length > 0;

export const getTags = (unitMeasure: TGenericUnitMeasure): Array<IProductCardTag> => {
  const tags: Array<IProductCardTag> = [];

  if (unitMeasure.hasPromotion) tags.push({ labelText: 'Promoción', type: EProductCardTagType.POSITIVE });
  if (unitMeasure.hasCombo) tags.push({ labelText: 'Arma tu combo', type: EProductCardTagType.INFO });

  return tags;
};

export const getInfoLine = (unitMeasure: TGenericUnitMeasure): IProductCardInfoLine | undefined => {
  if (unitMeasure.maxDiscount)
    return {
      text: `Hasta ${(unitMeasure.maxDiscount * 1000) / 10}% de dscto`,
      icon: { name: 'promotion', weight: 'fill' },
    };

  if (unitMeasure.hasGift) return { text: '¡Llévate un regalo!', icon: { name: 'gift', weight: 'fill' } };
};

export const getProductTotalDiscountValue = (discounts: Array<IPromoCart> = []) =>
  discounts.reduce((current, promo) => {
    const value = promo.value || 0;
    return current + value;
  }, 0);

export const mapProductToCardProductData = ({
  product,
  cartProduct,
  customerSource,
  canPurchaseDex,
  canPurchaseApudex,
}: IProductToCardProductDataParams): ICardProductData => {
  const finalProduct = cloneDeepSimple(product);

  if (cartProduct) {
    finalProduct.stock = cartProduct.stock;
    finalProduct.presentation = cartProduct.presentation;
    finalProduct.maximumSaleQuantity = cartProduct?.maximumSaleQuantity;
    finalProduct.minimumSaleQuantity = cartProduct?.minimumSaleQuantity;
    finalProduct.price = cartProduct?.price;
    finalProduct.originalPrice = cartProduct?.originalPrice;
  }

  const {
    currency,
    image,
    originalPrice,
    stock,
    presentation,
    maximumSaleQuantity,
    minimumSaleQuantity,
    selectedUnitMeasure,
    commercialName,
  } = finalProduct;

  const isActiveProduct = canPurchaseProduct({
    productSource: product.sourceId,
    customerSource,
    canPurchaseDex,
    canPurchaseApudex,
  });

  return {
    product: {
      active: isActiveProduct,
      currencySymbol: currency?.symbol,
      finalPrice: getProductFinalPrice(finalProduct),
      highlightFinalPrice: shouldHighlightFinalPrice(selectedUnitMeasure),
      image,
      regularPrice: originalPrice,
      stock,
      subtitle: presentation,
      title: commercialName,
      maximumSaleQuantity,
      minimumSaleQuantity,
    },
    unitMeasure: cartProduct?.selectedUnitMeasure ?? selectedUnitMeasure,
    tags: getTags(product.selectedUnitMeasure),
    infoLine: getInfoLine(product.selectedUnitMeasure),
  };
};

export const productToSearchProductPreviewProps = (product: IProduct): ISearchCardProductData => ({
  product: {
    title: getProductCommercialName(product.commercialName, product.name),
    regularPrice: product.originalPrice,
    highlightFinalPrice: shouldHighlightFinalPrice(product.selectedUnitMeasure),
    image: product.image,
    subtitle: product.presentation,
    finalPrice: product.price,
    currencySymbol: product.currency.symbol,
    active: true,
    stock: product.stock,
  },
  tags: getTags(product.selectedUnitMeasure),
  infoLine: getInfoLine(product.selectedUnitMeasure),
});
