import { DEFAULT_CURRENCY } from 'core/constants/currency.constants';
import { CART_VALIDATION_ERRORS_ID } from 'core/constants/shopping-cart.constants';
import { ECartError } from 'core/model/enums/shopping-cart.enum';
import { IError } from 'core/model/interfaces/error.interface';
import { IAddedProduct, ICartProduct } from 'core/model/interfaces/product.interface';
import { IShoppingCart, IShoppingCartGroup } from 'core/model/interfaces/shopping-cart.interface';
import { EMPTY_SHOPPING_CART_GROUP } from 'core/store/cart/constants';
import {
  IGetShoppingCartDTO,
  IPutShoppingCartRequestParams,
  IShoppingCartGroupBySourceDTO,
  IShoppingCartItemDTO,
} from './shopping-cart.dto';

const mapShoppingCartItemToProduct = (item: IShoppingCartItemDTO): ICartProduct => {
  const { unitMeasure } = item.product;

  return {
    amounts: {
      base: item.totals.base,
      final: item.totals.final,
      igv: item.totals.igv,
      perception: item.totals.perception,
      price: item.totals.price,
      discount: item.totals.discount,
    },
    brand: item.product.brand,
    categoryId: Number(item.product.externalCategory.id),
    categoryName: item.product.externalCategory.name,
    currency: DEFAULT_CURRENCY,
    description: item.product.description,
    promos: item.promotions
      ? item.promotions.map(promo => ({
          id: promo.id,
          name: promo.description,
          value: promo.discount,
        }))
      : [],
    eanCode: item.product.eanCode,
    image: item.product.image,
    maximumSaleQuantity: unitMeasure.maximumSaleQuantity,
    minimumSaleQuantity: unitMeasure.minimumSaleQuantity,
    name: item.product.name,
    commercialName: item.product.commercialName,
    presentation: unitMeasure.presentation,
    price: unitMeasure.price,
    originalPrice: unitMeasure.originalPrice,
    providerDisplayName: item.product.externalProvider.name,
    quantity: item.quantity,
    sku: item.product.sku,
    sourceId: item.product.sourceId,
    stock: item.product.stock,
    subcategoryId: Number(item.product.externalFamily.id),
    subcategoryName: item.product.externalFamily.name,
    reducedQuantity: item.quantityReducedByStock || 0,
    taxRate: 0,
    hasDiscount: item.promotions.length > 0,
    selectedUnitMeasure: {
      code: unitMeasure.code,
      default: unitMeasure.default,
      name: unitMeasure.description,
      presentation: unitMeasure.presentation,
      conversionFactor: unitMeasure.conversionFactor,
      maximumSaleQuantity: unitMeasure.maximumSaleQuantity,
      minimumSaleQuantity: unitMeasure.minimumSaleQuantity,
      originalPrice: unitMeasure.originalPrice,
      price: unitMeasure.price,
      promotions: unitMeasure.promotions,
      hasDiscount: item.promotions.length > 0,
    },
    metadata: {
      index: item.metadata?.index ?? 1,
      itemListName: item.metadata?.itemListName ?? '',
      origin: item.metadata?.origin ?? '',
    },
    distributorName: item.product.externalDistributor.name,
  };
};

const mapItemsGroup = (itemsGroup?: IShoppingCartGroupBySourceDTO): IShoppingCartGroup => {
  if (!itemsGroup) return EMPTY_SHOPPING_CART_GROUP;
  return {
    products: itemsGroup.items ? itemsGroup.items.map(item => mapShoppingCartItemToProduct(item)) : [],
    amounts: itemsGroup.totals,
    minimumSaleAmount:
      Boolean(itemsGroup.minimumSaleAmount) && itemsGroup.items?.length > 0 ? itemsGroup.minimumSaleAmount : 0,
  };
};

export const mapCartDTOToShoppingCart = (response: IGetShoppingCartDTO): IShoppingCart => {
  const data: IShoppingCart = {
    deletedProducts: response.deletedProductsByStock
      ? response.deletedProductsByStock.map(dp => ({
          displayName: dp.commercialName,
          sku: dp.id,
          image: dp.image,
          presentation: dp.presentation,
        }))
      : [],
    amounts: {
      base: response.totals.base,
      final: response.totals.final,
      igv: response.totals.igv,
      perception: response.totals.perception,
      price: response.totals.price,
      discount: response.totals.discount,
    },
    itemsGroups: {
      alliedItems: mapItemsGroup(response.alliedCart),
      alicorpItems: mapItemsGroup(response.alicorpCart),
      generalItems: mapItemsGroup(response.generalCart),
    },
    currency: DEFAULT_CURRENCY,
    errorType: getErrorType(response.errors),
  };
  return data;
};

const getErrorType = (errors: Array<IError>) =>
  errors.some(e => CART_VALIDATION_ERRORS_ID.includes(e.id)) ? ECartError.SHOW_USER_VALIDATION_ERROR : null;

export const mapChangeProductParamsToPutParams = (params: IAddedProduct): IPutShoppingCartRequestParams => ({
  externalProductId: params.sku,
  externalUnitMeasureId: params.unitMeasure.code,
  suggestedProductType: params.suggestedProductType,
  quantity: params.quantity,
  metadata: params.metadata,
});
