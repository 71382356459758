import { useState } from 'react';
import { Form } from '@alicorpdigital/dali-components-react';
import { Button } from '@insuma/mpp-ui/components/button';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { ImageRoutes } from 'core/constants/image-routes';
import { IBusiness } from 'core/model/interfaces/business.interface';
import { EMessageBoxType, MessageBox } from 'shared/components/message-box';
import { formatCkeckBoxAddressLabel } from 'shared/utils/format-address.utils';

import './address-selector.scss';

export interface IAddressSelectorProps {
  selectedAddress: Partial<IBusiness>;
  addresses: Array<IBusiness>;
  onChangeAddress: (business: IBusiness) => void;
  setSelectorAddressOpen: (state: boolean) => void;
}

export const AddressSelector = ({
  selectedAddress,
  addresses,
  onChangeAddress,
  setSelectorAddressOpen,
}: IAddressSelectorProps) => {
  const css = useCSS('address-selector');
  const [localSelectedAddress, setLocalSelectedAddress] = useState<IBusiness>(selectedAddress as IBusiness);

  const onSaveSelectedAddress = () => {
    onChangeAddress(localSelectedAddress);
    setSelectorAddressOpen(false);
  };

  return (
    <div className={css()}>
      <div className={css('header')}>
        <Button variant="tertiary" className={css('header-button')} onClick={() => setSelectorAddressOpen(false)}>
          <Icon aria-label="Cerrar selector de dirección" name="x" size="lg" />
        </Button>
        <h2 className={css('title')}>Selecciona una dirección de envio</h2>
      </div>

      <div className={css('body')}>
        <span className={css('subtitle')}>
          El stock y catálogo va a variar según la dirección de envío que selecciones.
        </span>
        <div className={css('addresses-container')}>
          <Form.Group className={css('options')}>
            {addresses.map(addressItem => (
              <Form.Check
                type="radio"
                role="radio"
                key={addressItem.id}
                id={addressItem.id}
                name={addressItem.address}
                inline
                onChange={() => setLocalSelectedAddress(addressItem)}
                checked={addressItem.id === localSelectedAddress.id}
                aria-labelledby={`address-label-${addressItem.id}`}
              >
                <span id={`address-label-${addressItem.id}`}>{formatCkeckBoxAddressLabel(addressItem)}</span>
              </Form.Check>
            ))}
          </Form.Group>
        </div>
        <MessageBox
          isFullWidth
          image={ImageRoutes.EMOJIN_FINGER_RIGHT}
          message="*Importante:* Tu carrito tendrá cambios al modificar la dirección."
          type={EMessageBoxType.WARNING}
        />
      </div>

      <footer className={css('footer')}>
        <Button onClick={() => onSaveSelectedAddress()} width="100%">
          Guardar cambios
        </Button>
      </footer>
    </div>
  );
};
