import { useNavigate } from 'react-router-dom';
import { Icon } from '@insuma/mpp-ui/components/icon';
import FixedBannerImg from 'assets/images/fixed-banner.png';
import { Path } from 'core/constants/path.constants';
import { useScreen } from 'core/contexts/screen.context';

import './fixed-banner.scss';

interface IFixedBannerProps {
  title: string;
}

export const FixedBanner = ({ title }: IFixedBannerProps) => {
  const { width } = useScreen();
  const isMobile = width <= 880;
  const navigate = useNavigate();

  return (
    <div className="fixed-banner" role="banner" aria-label="banner productos sugeridos">
      <img src={FixedBannerImg} alt="background banner" width="92" height="72" />
      <div className="fixed-banner__body">
        <div className="fixed-banner__title">{title}</div>
        <div
          aria-hidden="true"
          role="button"
          className={`fixed-banner__link--${isMobile ? 'mobile' : 'desktop'}`}
          onClick={() => navigate(Path.SUGGESTED_PRODUCTS_PAGE)}
        >
          {!isMobile && 'Conocer más'} <Icon name="caret-right" size="sm" />{' '}
        </div>
      </div>
    </div>
  );
};
