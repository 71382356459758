import { ECurrencies } from 'core/model/enums/currency.enum';
import { ICartProduct, IDeletedProduct, IMetadata, IProduct } from 'core/model/interfaces/product.interface';
import { getHasReducedProducts } from 'core/store/cart/cart-state.utils';
import { pushObjectDataLayer } from './gtm.events';

interface IBasicEcommerceItemGTM {
  item_name: string;
  item_id: string;
  price: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_list_id: string;
  item_list_name: string;
  index: number;
  quantity: number;
  product_ean: string;
  product_sku: string;
  dex: string;
  metadata: string;
  presentacion: string;
  procedencia: string;
}

interface IEcommerceItemAddToCart extends IBasicEcommerceItemGTM {
  aplica_descuento: 'Si' | 'No';
  limitado: 'Si' | 'No';
}

interface IAddProductToShoppingCartProps {
  product: IProduct;
  quantity: number;
  metadata: IMetadata;
  hasReachedMaxStock: boolean;
}

export const addProductToShoppingCartEvent = ({
  product,
  metadata,
  quantity,
  hasReachedMaxStock,
}: IAddProductToShoppingCartProps) => {
  const items: Array<IEcommerceItemAddToCart> = [
    {
      item_name: product.name,
      item_id: product.sku,
      price: product.price,
      item_brand: product.brand,
      item_category: product.categoryName,
      item_category2: product.subcategoryName,
      item_list_id: metadata.itemListName,
      item_list_name: metadata.itemListName,
      index: metadata.index,
      quantity,
      product_ean: product.eanCode,
      product_sku: product.sku,
      metadata: metadata.origin,
      presentacion: product.presentation,
      dex: product.distributorName,
      procedencia: product.providerDisplayName,
      aplica_descuento: product.selectedUnitMeasure.hasPromotion ? 'Si' : 'No',
      limitado: hasReachedMaxStock ? 'Si' : 'No',
    },
  ];

  const data = {
    event: 'add_to_cart',
    ecommerce: {
      currency: product.currency.id,
      items,
    },
  };
  pushObjectDataLayer(data, addProductToShoppingCartEvent.name);
};

interface IEcommerceItemViewCart extends IBasicEcommerceItemGTM {
  discount: number;
  estado: 'Reducido' | 'Sin alterar' | 'Eliminado';
}

interface IViewShoppingCartProps {
  products: Array<ICartProduct>;
  deletedProducts: Array<IDeletedProduct>;
}

export const viewShoppingCartEvent = ({ products, deletedProducts }: IViewShoppingCartProps) => {
  const transformCartProduct = (product: ICartProduct): IEcommerceItemViewCart => ({
    item_name: product.name,
    item_id: product.sku,
    price: product.price,
    item_brand: product.brand,
    item_category: product.categoryName,
    item_category2: product.subcategoryName,
    item_list_id: product.metadata?.itemListName,
    item_list_name: product.metadata?.itemListName,
    index: product.metadata?.index,
    quantity: product.quantity,
    dex: product.distributorName,
    product_ean: product.eanCode,
    product_sku: product.sku,
    metadata: product.metadata?.origin,
    presentacion: product.presentation,
    procedencia: product.providerDisplayName,
    discount: product.amounts.discount,
    estado: product.reducedQuantity ? 'Reducido' : 'Sin alterar',
  });

  const transformDeletedProduct = (product: IDeletedProduct): IEcommerceItemViewCart => ({
    item_name: product.displayName,
    item_id: `${product.sku}`,
    price: 0,
    item_brand: 'null',
    item_category: 'null',
    item_category2: 'null',
    item_list_id: 'null',
    item_list_name: 'null',
    index: 0,
    quantity: 0,
    dex: 'null',
    product_ean: 'null',
    product_sku: `${product.sku}`,
    metadata: 'null',
    presentacion: product.presentation,
    procedencia: 'null',
    discount: 0,
    estado: 'Eliminado',
  });

  const hasReducedProducts = getHasReducedProducts(products);

  const getGeneralState = () => {
    const hasDeletedProducts = deletedProducts.length > 0;
    if (hasReducedProducts && hasDeletedProducts) return 'Con productos removidos y eliminados';
    if (hasDeletedProducts) return 'Con productos eliminados';
    if (hasReducedProducts) return 'Con productos removidos';
    return 'Sin alteracion';
  };

  const items = [...products.map(transformCartProduct), ...deletedProducts.map(transformDeletedProduct)];

  const data = {
    event: 'view_cart',
    ecommerce: {
      currency: products[0]?.currency?.id || ECurrencies.PEN,
      estado_general: getGeneralState(),
      items,
    },
  };

  pushObjectDataLayer(data, viewShoppingCartEvent.name);
};

interface IEcommerceItemRemoveFromCart extends IBasicEcommerceItemGTM {
  aplica_descuento: 'Si' | 'No';
}

interface IRemoveProductFromShoppingCartProps {
  product: IProduct;
  quantityBeforeRemoval: number;
  metadata: IMetadata;
}

export const removeProductFromShoppingCartEvent = ({
  product,
  quantityBeforeRemoval,
  metadata,
}: IRemoveProductFromShoppingCartProps) => {
  const items: Array<IEcommerceItemRemoveFromCart> = [
    {
      item_name: product.name,
      item_id: product.sku,
      price: product.price,
      item_brand: product.brand,
      item_category: product.categoryName,
      item_category2: product.subcategoryName,
      item_list_id: metadata.itemListName,
      item_list_name: metadata.itemListName,
      index: metadata.index,
      quantity: quantityBeforeRemoval,
      dex: product.distributorName,
      product_ean: product.eanCode,
      product_sku: product.sku,
      metadata: metadata.origin,
      presentacion: product.presentation,
      procedencia: product.providerDisplayName,
      aplica_descuento: product.selectedUnitMeasure.hasPromotion ? 'Si' : 'No',
    },
  ];

  const data = {
    event: 'remove_from_cart',
    ecommerce: {
      currency: product.currency.id,
      items,
    },
  };
  pushObjectDataLayer(data, removeProductFromShoppingCartEvent.name);
};
