import * as tokens from '@alicorpdigital/dali-design-tokens/build/web/theme-insuma/tokens';

export const insumaTheme = {
  brandPrimaryDarkest: tokens.DlColorBrandPrimaryDarkest,
  brandPrimaryDark: tokens.DlColorBrandPrimaryDark,
  brandPrimaryMedium: tokens.DlColorBrandPrimaryMedium,
  brandPrimaryLight: tokens.DlColorBrandPrimaryLight,
  brandPrimaryLightest: tokens.DlColorBrandPrimaryLightest,
  brandHighlightDarkest: tokens.DlColorBrandHighlightDarkest,
  brandHighlightDark: tokens.DlColorBrandHighlightDark,
  brandHighlightMedium: tokens.DlColorBrandHighlightMedium,
  brandHighlightLight: tokens.DlColorBrandHighlightLight,
  brandHighlightLightest: tokens.DlColorBrandHighlightLightest,
  colorNeutralDarkest: tokens.DlColorNeutralDarkest,
  colorNeutralDark: tokens.DlColorNeutralDark,
  colorNeutralMedium: tokens.DlColorNeutralMedium,
  colorNeutralLight: tokens.DlColorNeutralLight,
  colorNeutralLightest: tokens.DlColorNeutralLightest,
  colorNeutralSnow: '#ffffff',
  colorDisabledLight: '#faf8f7',
  colorDisabledMedium: '#d9d5d4',
  colorDisabledDark: '#7a706d',
  colorSupportPositiveDarkest: tokens.DlColorSupportPositiveDarkest,
  colorSupportPositiveDark: tokens.DlColorSupportPositiveDark,
  colorSupportPositiveMedium: tokens.DlColorSupportPositiveMedium,
  colorSupportPositiveLight: tokens.DlColorSupportPositiveLight,
  colorSupportPositiveLightest: tokens.DlColorSupportPositiveLightest,
  colorSupportWarningDarkest: tokens.DlColorSupportWarningDarkest,
  colorSupportWarningDark: tokens.DlColorSupportWarningDark,
  colorSupportWarningMedium: tokens.DlColorSupportWarningMedium,
  colorSupportWarningLight: tokens.DlColorSupportWarningLight,
  colorSupportWarningLightest: tokens.DlColorSupportWarningLightest,
  colorSupportNegativeDarkest: tokens.DlColorSupportNegativeDarkest,
  colorSupportNegativeDark: tokens.DlColorSupportNegativeDark,
  colorSupportNegativeMedium: tokens.DlColorSupportNegativeMedium,
  colorSupportNegativeLight: tokens.DlColorSupportNegativeLight,
  colorSupportNegativeLightest: tokens.DlColorSupportNegativeLightest,
  borderRadiusSharp: tokens.DlBorderRadiusSharp,
  borderRadiusSm: tokens.DlBorderRadiusSm,
  borderRadiusMd: tokens.DlBorderRadiusMd,
  borderRadiusLg: tokens.DlBorderRadiusLg,
  borderRadiusPill: tokens.DlBorderRadiusPill,
  borderRadiusCircular: tokens.DlBorderRadiusCircular,
  borderWidthNone: tokens.DlBorderWidthNone,
  borderWidthHairline: tokens.DlBorderWidthHairline,
  borderWidthThick: tokens.DlBorderWidthThick,
  borderWidthHeavy: tokens.DlBorderWidthHeavy,
  gridBreakpointMobile: tokens.DlGridBreakpointMobile,
  gridBreakpointDesktop: tokens.DlGridBreakpointDesktop,
  opacitySemiOpaque: tokens.DlOpacitySemiOpaque,
  opacityIntense: tokens.DlOpacityIntense,
  opacityLight: tokens.DlOpacityLight,
  opacitySemiTransparent: tokens.DlOpacitySemiTransparent,
  shadowUp01: tokens.DlShadowUp01,
  shadowUp02: tokens.DlShadowUp02,
  shadowUp03: tokens.DlShadowUp03,
  shadowDown01: tokens.DlShadowDown01,
  shadowDown02: tokens.DlShadowDown02,
  shadowDown03: tokens.DlShadowDown03,
  spacingInlineQuarck: tokens.DlSpacingInlineQuarck,
  spacingInlineNano: tokens.DlSpacingInlineNano,
  spacingInlineMicro: tokens.DlSpacingInlineMicro,
  spacingInlineXxxs: tokens.DlSpacingInlineXxxs,
  spacingInlineXxs: tokens.DlSpacingInlineXxs,
  spacingInlineXs: tokens.DlSpacingInlineXs,
  spacingInlineSm: tokens.DlSpacingInlineSm,
  spacingInlineMd: tokens.DlSpacingInlineMd,
  spacingInlineLg: tokens.DlSpacingInlineLg,
  spacingInlineXl: tokens.DlSpacingInlineXl,
  spacingInlineQuarckRem: tokens.DlSpacingInlineQuarckRem,
  spacingInlineNanoRem: tokens.DlSpacingInlineNanoRem,
  spacingInlineMicroRem: tokens.DlSpacingInlineMicroRem,
  spacingInlineXxxsRem: tokens.DlSpacingInlineXxxsRem,
  spacingInlineXxsRem: tokens.DlSpacingInlineXxsRem,
  spacingInlineXsRem: tokens.DlSpacingInlineXsRem,
  spacingInlineSmRem: tokens.DlSpacingInlineSmRem,
  spacingInlineMdRem: tokens.DlSpacingInlineMdRem,
  spacingInlineLgRem: tokens.DlSpacingInlineLgRem,
  spacingInlineXlRem: tokens.DlSpacingInlineXlRem,
  spacingInsetNano: tokens.DlSpacingInsetNano,
  spacingInsetXs: tokens.DlSpacingInsetXs,
  spacingInsetSm: tokens.DlSpacingInsetSm,
  spacingInsetMd: tokens.DlSpacingInsetMd,
  spacingInsetLg: tokens.DlSpacingInsetLg,
  spacingInsetXl: tokens.DlSpacingInsetXl,
  spacingInsetNanoRem: tokens.DlSpacingInsetNanoRem,
  spacingInsetXsRem: tokens.DlSpacingInsetXsRem,
  spacingInsetSmRem: tokens.DlSpacingInsetSmRem,
  spacingInsetMdRem: tokens.DlSpacingInsetMdRem,
  spacingInsetLgRem: tokens.DlSpacingInsetLgRem,
  spacingInsetXlRem: tokens.DlSpacingInsetXlRem,
  spacingSquishNano: tokens.DlSpacingSquishNano,
  spacingSquishXs: tokens.DlSpacingSquishXs,
  spacingSquishSm: tokens.DlSpacingSquishSm,
  spacingSquishMd: tokens.DlSpacingSquishMd,
  spacingSquishNanoRem: tokens.DlSpacingSquishNanoRem,
  spacingSquishXsRem: tokens.DlSpacingSquishXsRem,
  spacingSquishSmRem: tokens.DlSpacingSquishSmRem,
  spacingSquishMdRem: tokens.DlSpacingSquishMdRem,
  spacingStackQuarck: tokens.DlSpacingStackQuarck,
  spacingStackNano: tokens.DlSpacingStackNano,
  spacingStackMicro: tokens.DlSpacingStackMicro,
  spacingStackXxxs: tokens.DlSpacingStackXxxs,
  spacingStackXxs: tokens.DlSpacingStackXxs,
  spacingStackXs: tokens.DlSpacingStackXs,
  spacingStackSm: tokens.DlSpacingStackSm,
  spacingStackMd: tokens.DlSpacingStackMd,
  spacingStackLg: tokens.DlSpacingStackLg,
  spacingStackXl: tokens.DlSpacingStackXl,
  spacingStackXxl: tokens.DlSpacingStackXxl,
  spacingStackXxxl: tokens.DlSpacingStackXxxl,
  spacingStackHuge: tokens.DlSpacingStackHuge,
  spacingStackQuarckRem: tokens.DlSpacingStackQuarckRem,
  spacingStackNanoRem: tokens.DlSpacingStackNanoRem,
  spacingStackMicroRem: tokens.DlSpacingStackMicroRem,
  spacingStackXxxsRem: tokens.DlSpacingStackXxxsRem,
  spacingStackXxsRem: tokens.DlSpacingStackXxsRem,
  spacingStackXsRem: tokens.DlSpacingStackXsRem,
  spacingStackSmRem: tokens.DlSpacingStackSmRem,
  spacingStackMdRem: tokens.DlSpacingStackMdRem,
  spacingStackLgRem: tokens.DlSpacingStackLgRem,
  spacingStackXlRem: tokens.DlSpacingStackXlRem,
  spacingStackXxlRem: tokens.DlSpacingStackXxlRem,
  spacingStackXxxlRem: tokens.DlSpacingStackXxxlRem,
  spacingStackHugeRem: tokens.DlSpacingStackHugeRem,
  fontFamilyLight: tokens.DlFontFamilyLight,
  fontFamilyRegular: tokens.DlFontFamilyRegular,
  fontFamilyBold: tokens.DlFontFamilyBold,
  fontFamilySemibold: tokens.DlFontFamilySemibold,
  fontFamilyMonospace: tokens.DlFontFamilyMonospace,
  fontSizeXxl: tokens.DlFontSizeXxlRem,
  fontSizeXl: tokens.DlFontSizeXlRem,
  fontSizeLg: tokens.DlFontSizeLgRem,
  fontSizeMd: tokens.DlFontSizeMdRem,
  fontSizeSm: tokens.DlFontSizeSmRem,
  fontSizeXs: tokens.DlFontSizeXsRem,
  fontSizeXxs: tokens.DlFontSizeXxsRem,
  fontSizeXxxs: tokens.DlFontSizeXxxsRem,
  fontSizeNano: tokens.DlFontSizeNanoRem,
  fontSizeQuarck: tokens.DlFontSizeQuarckRem,
  fontWeightLight: tokens.DlFontWeightLight,
  fontWeightRegular: tokens.DlFontWeightRegular,
  fontWeightSemibold: tokens.DlFontWeightSemibold,
  fontWeightBold: tokens.DlFontWeightBold,
  lineHeightTight: tokens.DlLineHeightTight,
  lineHeightFair: tokens.DlLineHeightFair,
  lineHeightConfy: tokens.DlLineHeightConfy,
};
